import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import Image from "gatsby-image"

import { media } from "../../styles/media"

import LangCn from "../../components/LangCn"
import LangLayout from "../../components/LangLayout"
import PageHeaderMedia from "../../components/PageHeaderMedia"
import SEO from "../../components/SEO"

const StyledContents = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 50px auto;
  h2 {
    color: #3a3a3a;
    font-size: 1.62em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 2.62em 0 1.62em;
    line-height: 1.1em;
    &:before {
      display: block;
      content: "";
      height: 1.32em;
      width: 8px;
      margin-right: 0.32em;
      border-radius: 1px;
      background: ${({ theme }) => theme.colors.primary.red};
    }
  }
`

const StyledTable = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  h4 {
    width: 30%;
    margin-bottom: 1.62rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary.green};
  }
  p {
    width: 68%;
    margin-bottom: 1.62rem;
  }
`

const StyledBiz = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  .gatsby-image-wrapper {
    width: 55%;
  }
  .bizSec {
    width: 40%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: center;
    margin: 1.62em 0;
    .gatsby-image-wrapper {
      width: 80%;
    }
    p {
      width: 95%;
      margin-top: 0.62em;
      color: gray;
      margin-bottom: 2.62em;
    }
  }
  ${() =>
    media.sp(css`
      justify-content: center;
      .gatsby-image-wrapper {
        width: 80%;
        margin-bottom: 1.62em;
      }
      .bizSec {
        width: 100%;
        p {
          width: 100%;
        }
      }
    `)}
`

const LangProductCn = () => {
  const data = useStaticQuery(query)
  return (
    <LangLayout>
      <SEO>
        <title>公司概要｜法兰索亚</title>
        <meta name="description" content="		 		 				公司概要 		  					创业 					19..." />
      </SEO>
      <PageHeaderMedia
        fluid={data.zoo.langHeaderInfo.headerMedia.childImageSharp.fluid}
        pageTitles={data.zoo.langHeaderInfo.headerTitleCn}
        pageText={data.zoo.langHeaderInfo.subTitleCn}
      />
      <StyledContents>
        <h2>{data.zoo.infos.profileTitle}</h2>
        <StyledTable>
          <h4>{data.zoo.infos.foundedTitle}</h4>
          <p dangerouslySetInnerHTML={{ __html: data.zoo.infos.foundedTxt }} />
          <h4>{data.zoo.infos.capitalTitle}</h4>
          <p dangerouslySetInnerHTML={{ __html: data.zoo.infos.capitalTxt }} />
          <h4>{data.zoo.infos.turnoverTitle}</h4>
          <p dangerouslySetInnerHTML={{ __html: data.zoo.infos.turnoverTxt }} />
          <h4>{data.zoo.infos.factoryTitle}</h4>
          <p dangerouslySetInnerHTML={{ __html: data.zoo.infos.factoryTxt }} />
          <h4>{data.zoo.infos.officeTitle}</h4>
          <p dangerouslySetInnerHTML={{ __html: data.zoo.infos.officeTxt }} />
          <h4>{data.zoo.infos.empTitle}</h4>
          <p dangerouslySetInnerHTML={{ __html: data.zoo.infos.empTxt }} />
          <h4>{data.zoo.infos.bizTitle}</h4>
          <p dangerouslySetInnerHTML={{ __html: data.zoo.infos.bizTxt }} />
        </StyledTable>
        <LangCn />
        <h2>{data.bota.cominfo.companyInfoTitle}</h2>
        <StyledBiz>
          <Image
            fluid={data.bota.cominfo.kyushuMapMedia.childImageSharp.fluid}
            alt="kyushuMap"
          />
          <div className="bizSec">
            <Image
              fluid={data.bota.cominfo.marketingMedia.childImageSharp.fluid}
              alt="marketingMedia"
            />
            <p>{data.bota.cominfo.marketingTxt}</p>
            <Image
              fluid={data.bota.cominfo.produceMedia.childImageSharp.fluid}
              alt="produceMedia"
            />
            <p>{data.bota.cominfo.produceText}</p>
            <Image
              fluid={data.bota.cominfo.flowerMedia.childImageSharp.fluid}
              alt="flowerMedia"
            />
            <p>{data.bota.cominfo.flowerText}</p>
          </div>
        </StyledBiz>
      </StyledContents>
    </LangLayout>
  )
}

export const query = graphql`
  {
    zoo: strapiLangInformation {
      langHeaderInfo {
        headerTitleCn
        subTitleCn
        headerMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      infos: langCompanyInfoCn {
        capitalTitle
        capitalTxt
        foundedTitle
        foundedTxt
        turnoverTitle
        turnoverTxt
        factoryTitle
        factoryTxt
        officeTitle
        officeTxt
        empTitle
        empTxt
        bizTitle
        bizTxt
        profileTitle
      }
    }
    bota: strapiLangBizIntro {
      cominfo: companyInfoLangCn {
        companyInfoTitle
        kyushuMapMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        marketingTxt
        marketingMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        produceText
        produceMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        flowerText
        flowerMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default LangProductCn
