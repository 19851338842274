import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../styles/media"

const StyledWrap = styled.div`
  width: 100%;
  .lady {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    .ladyImage {
      width: 28%;
      height: 200px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      .gatsby-image-wrapper {
        height: 100%;
        width: 100px;
      }
    }
    .ladyText {
      width: 70% !important;
      h3 {
        font-size: 1.32em;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background: ${({ theme }) => theme.colors.primary.green};
          margin: 0.62em 0 1.62em;
        }
      }
    }
  }
`

const StyledMap = styled.div`
  width: 400px;
  margin: 20px auto 50px;
  .gatsby-image-wrapper {
    width: 100%;
  }
  ${() =>
    media.sp(css`
      width: 75%;
    `)}
`

const StyledShop = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  .shopList {
    width: 30%;
    h4 {
      width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary.green};
      color: ${({ theme }) => theme.colors.primary.green};
    }
    .shopPhoto {
      width: 100%;
      margin: 0.62em 0;
      #photoDetails {
        color: gray;
        font-size: 0.9em;
        padding: 0.32em 0;
      }
    }
    h5 {
      width: 100%;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary.red};
    }
    p {
      width: 100%;
      padding: 0.32em 0 1.62em;
      font-size: 0.9em;
    }
  }
  ${() =>
    media.sp(css`
      .shopList {
        width: 48%;
        p {
          font-size: 0.8em;
        }
      }
    `)}
`

const LangEn = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <h2>{data.zoo.bota.chinaTitle}</h2>
      <div className="lady">
        <div className="ladyImage">
          <Image
            fluid={data.zoo.bota.chinaCasis.childImageSharp.fluid}
            alt="casisLady"
          />
        </div>
        <div className="ladyText">
          <h3 dangerouslySetInnerHTML={{ __html: data.zoo.bota.chinaText }} />
          <p>{data.zoo.bota.chinaSubTItles}</p>
        </div>
      </div>
      <StyledMap>
        <Image
          fluid={data.zoo.bota.chinaMap.childImageSharp.fluid}
          alt="chinaMap"
        />
      </StyledMap>
      <StyledShop>
        <div className="shopList">
          <h4>{data.zoo.bota.shop1name}</h4>
          <div className="shopPhoto">
            <Image
              fluid={data.zoo.bota.shop1Media.childImageSharp.fluid}
              alt="chinaShop"
            />
            <p id="photoDetails">{data.zoo.bota.shop1mediaTxt}</p>
          </div>
          <h5>{data.zoo.bota.shop1AddTitle}</h5>
          <p>{data.zoo.bota.shop1addTxt}</p>
          <h5>{data.zoo.bota.shop1estTitle}</h5>
          <p>{data.zoo.bota.shop1estTxt}</p>
          <h5>{data.zoo.bota.shop1capitalTitle}</h5>
          <p>{data.zoo.bota.shop1capTxt}</p>
          <h5>{data.zoo.bota.shop1detailsTitle}</h5>
          <p
            dangerouslySetInnerHTML={{ __html: data.zoo.bota.shop1detailTxt }}
          />
        </div>
        <div className="shopList">
          <h4>{data.zoo.bota.shop2name}</h4>
          <div className="shopPhoto">
            <Image
              fluid={data.zoo.bota.shop2media.childImageSharp.fluid}
              alt="chinaShop"
            />
            <p id="photoDetails">{data.zoo.bota.shop2mediaTxt}</p>
          </div>
          <h5>{data.zoo.bota.shop2addressTitle}</h5>
          <p>{data.zoo.bota.shop2addressTxt}</p>
          <h5>{data.zoo.bota.shop2estTitle}</h5>
          <p>{data.zoo.bota.shop2estTxt}</p>
          <h5>{data.zoo.bota.shop2capitalTitle}</h5>
          <p
            dangerouslySetInnerHTML={{ __html: data.zoo.bota.shop2capitalTxt }}
          />
          <h5>{data.zoo.bota.shop2detailTitle}</h5>
          <p
            dangerouslySetInnerHTML={{ __html: data.zoo.bota.shop2detailTxt }}
          />
        </div>
        <div className="shopList">
          <h4>{data.zoo.bota.shop3name}</h4>
          <div className="shopPhoto">
            <Image
              fluid={data.zoo.bota.shop3Media.childImageSharp.fluid}
              alt="chinaShop"
            />
            <p id="photoDetails">{data.zoo.bota.shop3mediaTxt}</p>
          </div>
          <h5>{data.zoo.bota.shop3addressTitle}</h5>
          <p>{data.zoo.bota.shop3addressTxt}</p>
          <h5>{data.zoo.bota.shop3estTitle}</h5>
          <p>{data.zoo.bota.shop3estTxt}</p>
          <h5>{data.zoo.bota.shop3capitalTitle}</h5>
          <p
            dangerouslySetInnerHTML={{ __html: data.zoo.bota.shop3capitalTxt }}
          />
          <h5>{data.zoo.bota.shop3bizTitle}</h5>
          <p dangerouslySetInnerHTML={{ __html: data.zoo.bota.shop3bizTxt }} />
        </div>
      </StyledShop>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    zoo: strapiLangBizIntro {
      bota: bizChinaCn {
        chinaTitle
        chinaText
        chinaSubTItles
        chinaCasis {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        chinaMap {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        shop1name
        shop1AddTitle
        shop1addTxt
        shop1estTitle
        shop1estTxt
        shop1capitalTitle
        shop1capTxt
        shop1detailsTitle
        shop1detailTxt
        shop1Media {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        shop1mediaTxt
        shop2name
        shop2addressTitle
        shop2addressTxt
        shop2estTitle
        shop2estTxt
        shop2capitalTitle
        shop2capitalTxt
        shop2detailTitle
        shop2detailTxt
        shop2mediaTxt
        shop2media {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        shop3name
        shop3addressTitle
        shop3addressTxt
        shop3estTitle
        shop3estTxt
        shop3capitalTitle
        shop3capitalTxt
        shop3bizTitle
        shop3bizTxt
        shop3mediaTxt
        shop3Media {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default LangEn
